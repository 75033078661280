import { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useEffect, useState } from "react"

export type ReservationType = {
    "departure-date": Date
    departure: string
    "departure-id"?: string
    arrival: string
    "arrival-id"?: string
    "radio-departure"?: "1" | "2" | null
    now: boolean
    termsAndConditions?: boolean
}

export type ReservationContextType = {
    reservation: ReservationType
    setReservation: Dispatch<SetStateAction<ReservationType>>
}

export const ReservationContext = createContext<ReservationContextType>({
    reservation: {
        "departure-date": new Date(),
        departure: "",
        arrival: "",
        now: true,
    },
    setReservation: () => {},
})

export const ReservationProvider = ({ children }: PropsWithChildren) => {
    const [reservation, setReservation] = useState<ReservationType>(() => {
        const storedReservation = localStorage.getItem("reservation")

        if (storedReservation && window.location.pathname !== "/") {
            const parsedReservation = JSON.parse(storedReservation)
            const { "departure-date": departureDate, ...rest } = parsedReservation
            return {
                ...rest,
                "departure-date": departureDate ? new Date(departureDate) : null,
            }
        } else {
            return {
                "departure-date": null,
                departure: null,
                arrival: null,
            }
        }
    })

    useEffect(() => {
        const storedReservation = localStorage.getItem("reservation")
        if (storedReservation) {
            const parsedReservation = JSON.parse(storedReservation)
            const { "departure-date": departureDate, ...rest } = parsedReservation
            setReservation({
                ...rest,
                "departure-date": departureDate ? new Date(departureDate) : null,
            })
        }

        // Ajouter cette condition pour vérifier que l'utilisateur est sur la page d'accueil
        if (window.location.pathname === "/") {
            localStorage.removeItem("reservation")
            setReservation({
                "departure-date": new Date(),
                departure: "",
                arrival: "",
                now: true,
            })
        }
    }, [])

    useEffect(() => {
        localStorage.setItem("reservation", JSON.stringify(reservation))
    }, [reservation])

    return <ReservationContext.Provider value={{ reservation, setReservation }}>{children}</ReservationContext.Provider>
}

export default ReservationContext

export const useReservation = (): ReservationContextType => useContext(ReservationContext)
