import { createMultiStyleConfigHelpers, defineStyle } from "@chakra-ui/react"
import { selectAnatomy } from "@chakra-ui/anatomy"

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = definePartsStyle({
    field: {
        borderRadius: "0.3rem",
        backgroundColor: "white",
        color: "black",
        h: "auto",
    },
    icon: {
        flexShrink: 0,
        w: "22px",
    },
})

const variants = {
    base: baseStyle,
}

const md = defineStyle({
    px: "1.4rem",
    h: { base: "3.2rem", lg: "6.2rem" },
    fontSize: { base: "1.6rem", lg: "1.6rem" },
})

const xl = defineStyle({
    px: "1.6rem",
    h: { base: "5.2rem", lg: "6.2rem" },
    fontSize: "1.6rem",
})

const sizes = {
    md: definePartsStyle({ field: md, addon: md }),
    xl: definePartsStyle({ field: xl, addon: xl }),
}

export const selectTheme = defineMultiStyleConfig({ variants, sizes })
