import { useLayoutEffect, useRef } from "react"
import { Route, Routes, useLocation } from "react-router-dom"
import DefaultTemplate from "@/components/templates/DefaultTemplate.tsx"
import PrivacyPolicy from "@/pages/Legals/PrivacyPolicy"
import TermsOfUse from "@/pages/Legals/TermsOfUse.tsx"
import HomePage from "@/pages/Home"
import AboutUsPage from "@/pages/AboutUs"
import ContactPage from "@/pages/Contact"
import EngagementsPage from "@/pages/Engagements"
import FAQPage from "@/pages/FAQ"
import HowItsWorkPage from "@/pages/HowItsWork"
import JoinUsPage from "@/pages/JoinUs"
import PaiementConfirmationPage from "@/pages/PaiementConfirmation"
import SubscriptionManagementPage from "@/pages/SubscriptionManagement"
import NotFound from "@/pages/NotFound/NotFound.tsx"
import RestoResutsPage from "@/pages/Results"

const Router = () => {
    const { pathname } = useLocation()

    const prevPathnameRef = useRef<string | undefined>()

    const isTaxi = import.meta.env.VITE_VERTICALE === "taxi"

    useLayoutEffect(() => {
        if (location.pathname !== "/paiement-confirmation") {
            window.dataLayer.push({
                event: "page_view",
            })
        }

        if (prevPathnameRef.current !== pathname) {
            window.scrollTo(0, 0)
        }
        prevPathnameRef.current = pathname
    }, [pathname])

    return (
        <Routes>
            <Route path="/paiement-confirmation" element={<PaiementConfirmationPage />} />
            <Route path="/" element={<DefaultTemplate />}>
                <Route index element={<HomePage />} />
                {isTaxi ? (
                    <>
                        <Route path={"/reserver-un-taxi"} element={<HomePage />} />
                        <Route path="/a-propos" element={<AboutUsPage />} />
                        <Route path="/contact" element={<ContactPage />} />
                        <Route path="/carriere" element={<JoinUsPage />} />
                        <Route path="/faq" element={<FAQPage />} />
                        <Route path="/engagements" element={<EngagementsPage />} />
                        <Route path="/comment-ca-marche" element={<HowItsWorkPage />} />
                        <Route path="/gerer-mon-abonnement" element={<SubscriptionManagementPage />} />
                    </>
                ) : (
                    <>
                        <Route path={"/reserver-un-restaurant"} element={<HomePage />} />
                        <Route path={"resultats"} element={<RestoResutsPage />} />
                    </>
                )}
                <Route path="/confidentialite" element={<PrivacyPolicy />} />
                <Route path="/mentions-legales-et-cgv" element={<TermsOfUse />} />
                <Route path="*" element={<NotFound />} />
            </Route>
        </Routes>
    )
}

export default Router
