import { useEffect, useMemo, useState } from "react"
import { Box, BoxProps, Flex, Heading, Text } from "@chakra-ui/react"
import BoxColumn from "../../atoms/BoxColumn/BoxColumn"
import { useLocation, useNavigate } from "react-router-dom"
import Menu from "./parts/Menu"
import { useRedirectUrl } from "@/contexts/SessionContext.tsx"
import { dynamicValue } from "@/utils/verticale.ts"
import { usePrice } from "@/utils/price.ts"

const Navbar = (props: BoxProps) => {
    const location = useLocation()
    const navigate = useNavigate()
    const redirectUrl = useRedirectUrl()
    const subscriptionPrice = usePrice()

    const conciergeName = useMemo(() => {
        if (redirectUrl.includes("top-resrv")) {
            return "Top Resrv"
        }

        if (redirectUrl.includes("book-ici")) {
            return "Book Ici"
        }

        if (redirectUrl.includes("reso-pro")) {
            return "Reso Pro"
        }

        if (redirectUrl.includes("reserv-go")) {
            return "Reserv Go"
        }

        if (redirectUrl.includes("servi-life")) {
            return "Servi Life"
        }

        if (redirectUrl.includes("easy-rsv")) {
            return "Easy Rsv"
        }

        if (redirectUrl.includes("reservup")) {
            return "Reservup"
        }

        if (redirectUrl.includes("now-servi")) {
            return "Now Servi"
        }

        return ""
    }, [redirectUrl])

    const [isHome, setIsHome] = useState<boolean>(false)
    const [bgColor, setBgColor] = useState<string>("")
    const [boxShadow, setBoxShadow] = useState<string>("")
    const [linkColor, setLinkColor] = useState<"white" | "black">("white")

    useEffect(() => {
        if (
            location.pathname === "/" ||
            location.pathname === "/reserver-un-taxi" ||
            location.pathname === "/reserver-un-restaurant" ||
            location.pathname === "/contact"
        ) {
            setIsHome(true)
            setBgColor("primary")
            setBoxShadow("none")
            setLinkColor("white")
        } else {
            setIsHome(false)
            setBgColor("white")
            setBoxShadow("0px 4px 4px rgba(0, 0, 0, 0.25)")
            setLinkColor("black")
        }
    }, [location])

    const redirectToHome = () => {
        navigate({
            pathname: "/",
            search: window.location.search,
        })
    }

    return (
        <Box
            bg={{ base: bgColor }}
            zIndex="999"
            boxShadow={{ lg: boxShadow }}
            position="relative"
            display={{ base: location.pathname !== "/paiement" ? "block" : "none", lg: "block" }}
            {...props}
        >
            {location.pathname === "/reserver-un-taxi" ||
            location.pathname === "/reserver-un-restaurant" ||
            import.meta.env.VITE_VERTICALE === "resto" ? (
                dynamicValue({
                    taxi: (
                        <BoxColumn h="100%" pt={{ base: "10px", lg: "36px" }}>
                            <Text fontSize={"1rem"} lineHeight={"1rem"} color={"white"}>
                                Réservez votre taxi en essayant la conciergerie gratuitement pendant 3 jours puis{" "}
                                {subscriptionPrice}eur par mois.
                            </Text>
                        </BoxColumn>
                    ),
                    resto: null,
                })
            ) : (
                <BoxColumn h="100%">
                    <Flex justify="space-between" h="100%">
                        <Flex
                            h="100%"
                            w="100%"
                            align="center"
                            gap={{ base: "1rem", lg: "1.2rem" }}
                            onClick={redirectToHome}
                            cursor="pointer"
                        >
                            <Heading
                                as="h1"
                                color={isHome ? "white" : "primary"}
                                fontWeight="700"
                                fontSize={{ base: "2.6rem", lg: "2.9rem" }}
                            >
                                {location.pathname === "/paiement-confirmation" ? conciergeName : "Taxi Reza"}
                                {location.pathname === "/paiement-confirmation" && (
                                    <Text
                                        as="span"
                                        fontSize={{ base: "1.2rem", lg: "1.5rem" }}
                                        ml={{ base: "0.5rem", lg: "1rem" }}
                                    >
                                        la conciergerie de Taxi Reza
                                    </Text>
                                )}
                            </Heading>
                        </Flex>
                        {location.pathname !== "/paiement" && <Menu h="100%" linkColor={linkColor} />}
                    </Flex>
                </BoxColumn>
            )}

            {/* Antialiasing */}
            <Box bg={{ base: bgColor }} zIndex="998" h="0.3rem" position="absolute" bottom="-0.2rem" w="100%" />
        </Box>
    )
}

export default Navbar
