import React, { Suspense } from "react";
import FallbackLoading from "../FallbackLoading";

const Contact = React.lazy(() => import("./Contact"));

const ContactPage = () => {
    return (
        <Suspense fallback={<FallbackLoading />}>
            <Contact />
        </Suspense>
    );
};

export default ContactPage;
