import { useRedirectUrl } from "@/contexts/SessionContext.tsx"

export const usePrice = () => {
    const redirectUrl = useRedirectUrl()
    const redirectUrlParams = new URLSearchParams(new URL(redirectUrl).search)

    if (redirectUrlParams.has("ps") && redirectUrlParams.get("ps") === "et") {
        return 69
    } else if (import.meta.env.VITE_VERTICALE === "taxi") {
        return 70
    } else {
        return 50
    }
}
