import { Outlet, useLocation } from "react-router-dom"
import Navbar from "@/components/organisms/Navbar/Navbar"
import Footer from "@/components/organisms/Footer/Footer"
import { useSession } from "@/contexts/SessionContext.tsx"
import React, { Suspense, useEffect } from "react"
import FallbackLoading from "@/pages/FallbackLoading.tsx"

export const NAVBAR_HEIGHT = { base: "6.5rem", lg: "8rem" }

const DefaultTemplate = () => {
    const location = useLocation()
    const session = useSession()

    const displayNavbar =
        (import.meta.env.VITE_VERTICALE === "taxi" && location.pathname !== "/paiement-confirmation") ||
        (import.meta.env.VITE_VERTICALE === "resto" && location.pathname === "/reserver-un-restaurant")

    const [isLoaded, setIsLoaded] = React.useState(false)

    useEffect(() => {
        if (displayNavbar) {
            if (
                (location.pathname === "/reserver-un-restaurant" || location.pathname === "/reserver-un-taxi") &&
                session.parcours !== "acquiz"
            ) {
                session.createOrUpdateCookieAndState({
                    redirectUrl: session.redirectUrl,
                    parcours: "acquiz",
                    abTest50ct: session.abTest50ct,
                    abTestReviewLP: session.abTestReviewLP,
                    abTestRestoLegal: session.abTestRestoLegal,
                })
            } else if (location.pathname === "/" && session.parcours !== "classic") {
                session.createOrUpdateCookieAndState({
                    redirectUrl: session.redirectUrl,
                    parcours: "classic",
                    abTest50ct: session.abTest50ct,
                    abTestReviewLP: session.abTestReviewLP,
                    abTestRestoLegal: session.abTestRestoLegal,
                })
            }
            setTimeout(() => {
                setIsLoaded(true)
            }, 10)
        } else {
            setIsLoaded(true)
        }
    }, [])

    return (
        <Suspense fallback={<FallbackLoading />}>
            {isLoaded && (
                <>
                    {displayNavbar && (
                        <Navbar
                            h={
                                location.pathname === "/reserver-un-taxi"
                                    ? {
                                          base: "6.5rem",
                                          lg: "12rem",
                                      }
                                    : NAVBAR_HEIGHT
                            }
                        />
                    )}

                    <Outlet />

                    <Footer />
                </>
            )}
        </Suspense>
    )
}

export default DefaultTemplate
